import {Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';

import {PermissionsService} from '@matchsource/store/permissions';

@Directive({
  selector: '[msUserPermissions]',
  standalone: true,
})
export class UserPermissionsDirective implements OnChanges {
  constructor(
    private readonly permissions: PermissionsService,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  @Input('msUserPermissions')
  userPermissions: string | string[];

  private updateView(permissions: string[]) {
    if (this.hasPermissions(permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private hasPermissions(permissions: string[]) {
    return this.permissions.hasPermissions(permissions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('userPermissions' in changes) {
      let permissions: string[];
      if (typeof this.userPermissions === 'string') {
        permissions = this.userPermissions.split(',');
      } else {
        permissions = this.userPermissions;
      }

      this.updateView(permissions);
    }
  }
}
